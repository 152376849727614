import Router, { useRouter } from "next/router"
import { ToastProvider } from "@common/toast/toastProvider"
import { ThemeProvider } from "next-themes"
import NProgress from "nprogress"
import * as gtag from "@common/v2/gtag"
import { SITE_CONFIG } from "configs/site"
import ErrorBoundary from "@common/v2/errorBoundary"
import "styles/global.css"
import Seo from "@common/seo"
import { getCustomerId } from "@common/lib/filters"
import { getHomePageUrl } from "@common/lib/navigation"

const GA_TRACKING_ID = SITE_CONFIG?.ga

// gTag & nProgress setup
// to show progress only on fetch and on delay follow this guide - https://dev.to/vvo/show-a-top-progress-bar-on-fetch-and-router-events-in-next-js-4df3
NProgress.configure({ showSpinner: false })
Router.events.on("routeChangeStart", () => {
  NProgress.start()
})
Router.events.on("routeChangeComplete", (url) => {
  gtag.pageview(url, GA_TRACKING_ID)
  NProgress.done()
})
Router.events.on("routeChangeError", () => NProgress.done())

export default function App({ Component, pageProps }) {
  const getLayout = Component.getLayout || ((page) => page)
  const router = useRouter()
  const homeUrl = getHomePageUrl(getCustomerId(router))

  return (
    <ErrorBoundary homeUrl={homeUrl}>
      <ThemeProvider attribute="class" defaultTheme="light">
        <SiteHead />
        <ToastProvider>{getLayout(<Component {...pageProps} />)}</ToastProvider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

function SiteHead() {
  const router = useRouter()
  const path = router.asPath?.split("?")[0]
  const titleString = SITE_CONFIG?.seo?.title
  const description = SITE_CONFIG?.seo?.description
  const homeImageUrl = SITE_CONFIG?.seo?.homeImageUrl
  const siteName = SITE_CONFIG?.seo?.siteName
  const locale = SITE_CONFIG?.seo?.locale
  const canonical = `${SITE_CONFIG?.seo?.url}${path == "/" ? "" : path}`

  return (
    <Seo
      title={titleString}
      description={description}
      canonical={canonical}
      homeImageUrl={homeImageUrl}
      siteName={siteName}
      locale={locale}
    />
  )
}
