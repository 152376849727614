import { ReactNode } from "react"
import { ToastOptions, toast } from "react-toastify"
import { ToastContainer, ToastContainerProps } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ToastCard, {
  ErrorToastCardContent,
  SuccessToastCardContent,
} from "@common/toast/toastCard"
import Svg from "@common/svg"
import { isFunction } from "@common/lib/util"
import { Status } from "@common/types"

export interface StatusOptions {
  pending?: string
  success?: string
  error?: string
}
export interface ToastOptionsType {
  position?:
    | "top-right"
    | "top-center"
    | "top-left"
    | "bottom-right"
    | "bottom-center"
    | "bottom-left"
  autoClose?: number | false
  hideProgressBar?: boolean
  closeOnClick?: boolean
  pauseOnHover?: boolean
  draggable?: boolean
  pending?: string
  success?: string
  error?: string
  onClose?: () => void
  type?: "info" | "success" | "warning" | "error" | "default" | "loading"
  message?: string | JSX.Element
  icon?: ReactNode
}

// showToast displays a toast notification with a specified content, type, and options.
export const showToastAdapter = (options: ToastOptionsType): string => {
  let toastId
  const { message, ...rest } = { ...options, closeButton: false }
  const _options = rest as ToastOptions

  if (typeof message === "string") {
    // will render content with any toast style (defined in ToastCard)
    switch (options.type) {
      case "info":
        toastId = toast.info(
          <ToastCard icon={<Svg classes="w-5 h-5 text-highlight" name="info" />}>
            {message}
          </ToastCard>,
          _options
        )
        break
      case "success":
        toastId = toast.success(
          <SuccessToastCardContent>{message}</SuccessToastCardContent>,
          _options
        )
        break
      case "warning":
        toastId = toast.warning(
          <ToastCard icon={<Svg classes="w-5 h-5 text-warning" name="warning" />}>
            {message}
          </ToastCard>,
          _options
        )
        break
      case "error":
        toastId = toast.error(
          <ErrorToastCardContent>{message}</ErrorToastCardContent>,
          _options
        )
        break
      case "loading":
        toastId = toast.loading(
          <ToastCard icon={<Svg name="arrow-path" classes="animate-spin h-4 w-4" />}>
            {message}
          </ToastCard>,
          _options
        )
        break
      default:
        toastId = toast(<ToastCard>{message}</ToastCard>, _options)
    }
  } else {
    // will render message without any toast style.
    toastId = toast(message, _options)
  }
  return toastId?.toString()
}

// showPromiseToast wraps a toast notification around a promise, displaying it based on the promise's state.
export const showPromiseToastAdapter = async (
  promiseOrFunction: Promise<any> | (() => Promise<any>),
  statusOptions: StatusOptions,
  options: ToastOptionsType
) => {
  const loadingToastId = showToastAdapter({
    ...options,
    message: statusOptions.pending,
    type: "loading",
  })
  const isFn = isFunction(promiseOrFunction)
  const response = isFn ? await promiseOrFunction() : await promiseOrFunction
  const isSuccess =
    response?.status === Status.Success || response?.data?.status === Status.Success

  toast.update(loadingToastId, {
    ...options,
    icon: null,
    render: isSuccess ? (
      <SuccessToastCardContent>
        {statusOptions.success || response?.message}
      </SuccessToastCardContent>
    ) : (
      <ErrorToastCardContent>
        {statusOptions.error || response?.message}
      </ErrorToastCardContent>
    ),
    type: isSuccess ? "success" : "error",
    isLoading: false,
    autoClose: options?.autoClose || 3000,
  })

  return response
}

// updateToast updates a specific toast with new options.
export const updateToastAdapter = (
  toastId: number | string,
  options: ToastOptionsType
) => {
  const isSuccess = options.type == "success"
  toast.update(toastId, {
    ...options,
    icon: null,
    render: isSuccess ? (
      <SuccessToastCardContent>{options.message}</SuccessToastCardContent>
    ) : (
      <ErrorToastCardContent>{options.message}</ErrorToastCardContent>
    ),
    type: isSuccess ? "success" : "error",
    isLoading: false,
    autoClose: options?.autoClose || 3000,
  })
}

// clearToast dismisses a specific toast.
export const clearToastAdapter = (toastId: number | string) => {
  toast.dismiss(toastId)
}

// ToastContainerWrapper is a wrapper component around react-toastify's ToastContainer.
export const ToastContainerWrapper = (props: ToastContainerProps) => {
  return <ToastContainer {...props} />
}
