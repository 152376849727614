import React, { ReactNode } from "react"
import { ButtonProps } from "@common/buttons/button"
import { ActionMenuProps } from "@common/dropdowns/actionMenu"
import { KeyedMutator } from "swr"

export enum LoginTypeEnum {
  "GoogleLogin",
  "Login",
  "Magiclink",
}

export enum SettingsEnum {
  "general",
  "email",
  "appearance",
}

export type Size = "xs" | "sm" | undefined | null

export interface Option {
  key?: string
  display: ReactNode
  disabled?: boolean
  invisible?: boolean
  selected?: boolean
  icon?: ReactNode // string (iconname) or jsx
}
export interface Options {
  [key: string]: Option
}

export type TabProps = {
  isSelected?: boolean
  isCompleted?: boolean
  index?: number
  onSelect: (index: number, e?: any) => void
  icon?: string
  children?: ReactNode // children or label can be used.
} & Pick<ButtonProps, "size">

export type TabGroupProps = {
  tabs?: Record<
    string,
    {
      display: ReactNode
    }
  >
  defaultTabKey?: string
  onSelect: (tab: string, e?: any) => void
} & Pick<TabProps, "size">

/**** API *****/
export enum Status {
  Failure = "FAILURE",
  Success = "SUCCESS",
  Otp = "OTP_PENDING",
  Error = "ERROR",
}

export type Message = {
  errors?: string[]
  warnings?: string[]
}

export type DisplayMessage = {
  overall: Message
  [key: string]: Message
}

/**** Network *****/
// TODO - move to network/types.ts
export interface NetworkResponse {
  data?: any
  isLoading?: boolean
  isError?: any
  isValidating?: boolean
  mutate?: KeyedMutator<any>
  message?: any
  status?: Status
  isToastShown?: boolean
}

export type APIResponse = {
  status: Status
  display_messages?: DisplayMessage
  user_message?: string
}

/**** Cards ****/
// TODO
// cardType need not be a part of CardProps
// deprecate cardActions
export type CardType = "flatCard" | "iconCard" | "imageCard" | "flowCard"
export interface CardProps {
  icon?: ReactNode
  id?: string
  header?: string
  cardType?: CardType
  description?: string
  url?: string
  imageUrl?: string
  image?: React.JSX.Element
  imageUrlDark?: string
  date?: string
  newTab?: boolean
  wrapLink?: boolean
  onClick?: (id?: string) => void
  disabled?: boolean
  disabledMsg?: string
  fallBackImg?: string
  sectionKey?: string
  actionMenu?: ActionMenuProps
  showTooltip?: boolean
  isDarkMode?: boolean

  // for flat card
  initials?: string[]
  cardWidthClasses?: string

  // for image card
  hasIcon?: boolean
  cardIcons?: string[] | undefined | null
  cardActions?: {
    [key: string]: {
      url?: string
      display?: string
      onClick?: (id: string, actionKey: string) => void
    }
  }

  alignment?: "left" | "center" | "right"
}

export type DispatchAction = {
  type: string
  payload?: any
}

declare global {
  /**
   * This snippet extends the global Window interface in TypeScript to include the 'grecaptcha' property.
   * `grecaptcha` is a part of the Google `reCAPTCHA` API which is loaded from `react-google-recaptcha` and not be recognized by TypeScript by default.
   * By declaring 'grecaptcha' as 'any', we bypass the strict type checking, thus preventing TypeScript compiler errors when accessing this property.
   */
  interface Window {
    grecaptcha: any
  }
}

export enum ResourceType {
  "CREW" = "CREW",
  "GENERATION" = "GENERATION",
  "DOCUMENT" = "DOCUMENT",
  "ILLUSTRATION" = "ILLUSTRATION",
  "FLOW" = "FLOW",
  "CRAWL-DATASET" = "CRAWL-DATASET",
  "CONNECTOR" = "CONNECTOR",
}

export enum GenerationAction {
  FETCH_CRAWL_DATASET = "FETCH_CRAWL_DATASET",
}
