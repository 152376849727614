/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactNode, createContext, useContext, useEffect } from "react"
import { useTheme } from "next-themes"

import {
  showToastAdapter,
  ToastContainerWrapper,
  StatusOptions,
  showPromiseToastAdapter,
  updateToastAdapter,
  clearToastAdapter,
  ToastOptionsType,
} from "./toastAdapter"

export type ShowPromiseToast = <T>(
  promise: Promise<T> | (() => Promise<T>),
  statusOptions: StatusOptions,
  options?: ToastOptionsType
) => Promise<T>
interface ToastContextType {
  showToast: (options?: ToastOptionsType) => string
  updateToast: (toastId: number | string, options?: ToastOptionsType) => void
  clearToast: (toastId: number | string) => void
  showPromiseToast: ShowPromiseToast
}

const ToastContext = createContext<ToastContextType>({
  showToast: () => "0",
  updateToast: () => {},
  clearToast: () => {},
  showPromiseToast: (() => Promise.resolve()) as <T = void>(
    promise: Promise<T> | (() => Promise<T>),
    options?: ToastOptionsType
  ) => Promise<T>,
})

export function ToastProvider({ children }: { children: ReactNode }) {
  const { theme } = useTheme()
  const isDarkMode = theme == "dark"

  //simple toast
  const showToast = (options: ToastOptionsType = {}) => {
    return showToastAdapter(options)
  }

  //makes changes to the currently displayed toast
  const updateToast = (toastId: number | string, options: ToastOptionsType = {}) => {
    options.icon = options.icon ?? null
    options.autoClose = options.autoClose ?? 1000
    options.message =
      options.type === "success"
        ? options.message ?? "Updated Successfully."
        : options.type === "error"
          ? options.message ?? "Update Failed."
          : options.message
    updateToastAdapter(toastId, options)
  }

  //clears specific toast
  const clearToast = (toastId: number | string) => {
    clearToastAdapter(toastId)
  }

  //toast-handles loading - success - failure for promises
  const showPromiseToast = async (
    promise: Promise<any> | (() => Promise<any>),
    statusOptions: StatusOptions,
    options: ToastOptionsType = {}
  ) => {
    const defaultStatusOptions: StatusOptions = {
      pending: "Loading...",
      // success: "Success!",
      // error: "Error!",
    }
    return showPromiseToastAdapter(
      promise,
      { ...defaultStatusOptions, ...statusOptions },
      options
    )
  }

  useEffect(() => {
    const showToastListener = (e: CustomEvent) => {
      const message = e.detail?.message
      const options = e.detail?.options
      if (message && options) {
        showToast({ ...options, message })
      }
    }

    window.addEventListener("show-toast", showToastListener as EventListener)
    return () =>
      window.removeEventListener("show-toast", showToastListener as EventListener)
  }, [showToast])

  return (
    <ToastContext.Provider
      value={{ showToast, showPromiseToast, updateToast, clearToast }}
    >
      {children}
      <ToastContainerWrapper
        theme={isDarkMode ? "dark" : "light"}
        toastClassName="border"
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick={false}
        pauseOnHover={true}
        draggable={false}
      />
    </ToastContext.Provider>
  )
}

export function useToast() {
  const { showToast, showPromiseToast, updateToast, clearToast } =
    useContext(ToastContext)
  return {
    showToast,
    showPromiseToast,
    updateToast,
    clearToast,
  }
}
