const defaultTheme = require("tailwindcss/defaultTheme")

module.exports = {
  experimental: {
    optimizeUniversalDefaults: false,
  },
  darkMode: "class",
  content: [
    "./components/**/*.{js,ts,jsx,tsx}",
    "./pages/**/*.{js,ts,jsx,tsx}",
    "./configs/**/*.{js,ts,jsx,tsx}",
    "./layouts/**/*.js",
    "./lib/**/*.js",
    "./data/**/*.mdx",
    "./common/src/**/*.{js,ts,jsx,tsx}",
  ],
  safelist: [
    {
      pattern: /text-xx./,
    },
    {
      pattern: /^w-(1|2|3|4|5|6|7|8|9|10|11|12)\/12$/,
    },
    {
      pattern: /pl-./,
    },
    {
      pattern: /h-./,
      variants: ["sm"],
    },
    {
      pattern: /pl-./,
    },
    {
      pattern: /grid-cols-./,
      variants: ["xl", "md", "lg", "sm"],
    },
  ], // All classes starting with grid-cols
  theme: {
    screens: {
      sm: "640px",
      // => @media (min-width: 640px) { ... }

      md: "768px",
      // => @media (min-width: 768px) { ... }

      lg: "1024px",
      // => @media (min-width: 1024px) { ... }

      xl: "1536px",
      // => @media (min-width: 1280px) { ... }

      xxl: "2000px", //wide screen
      // => @media (min-width: 1600px) { ... }
    },

    extend: {
      spacing: {
        "9/16": "56.25%",
      },
      lineHeight: {
        11: "2.75rem",
        12: "3rem",
        13: "3.25rem",
        14: "3.5rem",
      },
      screens: {
        print: { raw: "print" },
        // => @media print { ... }
      },
      fontFamily: {
        sans: ["Inter,sans-serif", ...defaultTheme.fontFamily.sans],
      },
      colors: {
        /* Default background color of <body />...etc */
        background: "var(--background)",
        foreground: "var(--foreground)",
        /* Background color for <Card /> */
        card: {
          DEFAULT: "var(--card)",
          foreground: "var(--card-foreground)",
        },

        /* Background color for popovers such as dropdowns, popovers, tooltips */
        popover: {
          DEFAULT: "var(--popover)",
          foreground: "var(--popover-foreground)",
        },

        /* Primary colors for <Button /> */
        primary: {
          DEFAULT: "var(--primary)",
          foreground: "var(--primary-foreground)",
        },

        /* Secondary colors for <Button /> */
        secondary: {
          DEFAULT: "var(--secondary)",
          foreground: "var(--secondary-foreground)",
        },
        /* Muted backgrounds such as <TabsList />, <Skeleton /> and <Switch /> */
        muted: {
          DEFAULT: "var(--muted)",
          foreground: "var(--muted-foreground)",
        },
        /* for accents such as hover effects on <DropdownMenuItem>, <SelectItem>...etc */
        accent: {
          DEFAULT: "var(--accent)",
          foreground: "var(--accent-foreground)",
        },

        destructive: {
          DEFAULT: "var(--destructive)",
          foreground: "var(--destructive-foreground)",
        },
        warning: {
          DEFAULT: "var(--warning)",
          foreground: "var(--warning-foreground)",
        },
        border: "var(--border)",
        input: "var(--input)",
        ring: "var(--ring)",
        highlight: "var(--highlight)",
      },

      // 📌 these power the "prose" classes, used in blog.
      typography: (theme) => ({
        DEFAULT: {
          css: {
            color: theme("colors.gray.900"),
            a: {
              color: theme("colors.gray.900"),
              "&:hover": {
                color: `${theme("colors.black")} !important`,
              },
              code: { color: theme("colors.primary.400") },
            },
            h1: {
              fontWeight: "700",
              letterSpacing: theme("letterSpacing.tight"),
              color: theme("colors.gray.900"),
            },
            h2: {
              fontWeight: "700",
              letterSpacing: theme("letterSpacing.tight"),
              color: theme("colors.gray.900"),
            },
            h3: {
              fontWeight: "600",
              color: theme("colors.gray.900"),
            },
            "h4,h5,h6": {
              color: theme("colors.gray.900"),
            },
            pre: {
              backgroundColor: theme("colors.gray.800"),
            },
            code: {
              color: theme("colors.pink.500"),
              backgroundColor: theme("colors.gray.100"),
              paddingLeft: "4px",
              paddingRight: "4px",
              paddingTop: "2px",
              paddingBottom: "2px",
              borderRadius: "0.25rem",
            },
            "code::before": {
              content: "none",
            },
            "code::after": {
              content: "none",
            },
            details: {
              backgroundColor: theme("colors.gray.100"),
              paddingLeft: "4px",
              paddingRight: "4px",
              paddingTop: "2px",
              paddingBottom: "2px",
              borderRadius: "0.25rem",
            },
            hr: { borderColor: theme("colors.gray.200") },
            "ol li::marker": {
              fontWeight: "600",
              color: theme("colors.gray.600"),
            },
            "ul li::marker": {
              backgroundColor: theme("colors.gray.500"),
            },
            strong: { color: theme("colors.gray.900") },
            blockquote: {
              color: theme("colors.gray.900"),
              borderLeftColor: theme("colors.gray.200"),
            },
          },
        },
        dark: {
          css: {
            color: theme("colors.gray.300"),
            a: {
              color: theme("colors.primary.500"),
              "&:hover": {
                color: `${theme("colors.primary.400")} !important`,
              },
              code: { color: theme("colors.primary.400") },
            },
            h1: {
              fontWeight: "700",
              letterSpacing: theme("letterSpacing.tight"),
              color: theme("colors.gray.100"),
            },
            h2: {
              fontWeight: "700",
              letterSpacing: theme("letterSpacing.tight"),
              color: theme("colors.gray.100"),
            },
            h3: {
              fontWeight: "600",
              color: theme("colors.gray.100"),
            },
            "h4,h5,h6": {
              color: theme("colors.gray.100"),
            },
            pre: {
              backgroundColor: theme("colors.gray.800"),
            },
            code: {
              backgroundColor: theme("colors.gray.800"),
            },
            details: {
              backgroundColor: theme("colors.gray.800"),
            },
            hr: { borderColor: theme("colors.gray.700") },
            "ol li::marker": {
              fontWeight: "600",
              color: theme("colors.gray.400"),
            },
            "ul li::marker": {
              backgroundColor: theme("colors.gray.400"),
            },
            strong: { color: theme("colors.gray.100") },
            thead: {
              th: {
                color: theme("colors.gray.100"),
              },
            },
            tbody: {
              tr: {
                borderBottomColor: theme("colors.gray.700"),
              },
            },
            blockquote: {
              color: theme("colors.gray.100"),
              borderLeftColor: theme("colors.gray.700"),
            },
          },
        },
      }),
      opacity: {
        10: "0.1",
      },
      zIndex: {
        25: "25",
        15: "15",
      },
      fontSize: {
        xxxs: "0.6rem",
        xxs: "0.7rem",
      },
      maxWidth: {
        "3xs": "12em",
        "2xs": "16em",
        "1/3": "33%",
        "8xl": "88rem",
        "9xl": "96rem",
      },
      maxHeight: {
        none: "none !important" /*will override inline styles*/,
      },
      boxShadow: {
        light: "0px 0px 1px 1px #d1d1d1 ",
        hoveredBlue: "0px 0px 1px 1px #0969da ",
        elevate1: "0px 4px 24px -2px rgba(0, 0, 0, 0.25) ",
        elevate1Dark: "0px 4px 24px -2px rgba(255, 255, 255, 0.25) ",
        elevate2: " 0px 11px 15px -9px rgba(189,189,189,1) ",
        elevate2Dark: " 0px 7px 15px -9px rgba(150,150,150,1) ",
        buttonShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10) ",
        buttonShadowDark: "0px 1px 2px 0px rgba(255, 255, 255, 0.10) ",
      },
      height: () => ({
        72: "18rem",
        80: "20rem",
        "screen-3/4": "75vh",
        "screen-5/6": "83vh",
        "screen-1/2": "50vh",
        "50px": "50px",
      }),
      keyframes: {
        fadeInFromLeft: {
          "0%": { transform: "translate(-15px)" },
          "100%": { transform: "translate(0)" },
        },
        mildFade: {
          "0%": { opacity: "0" },
          "100%": { opacity: "1" },
        },
        loadFadeUp: {
          "0%": { opacity: "0", transform: "translateY(100px)" },
          "100%": { opacity: "1", transform: "translateY(0px)" },
        },
        loadMildFadeUp: {
          "0%": { opacity: "0", transform: "translateY(40px)" },
          "100%": { opacity: "1", transform: "translateY(0px)" },
        },
        loadFadeFromRight: {
          "0%": { opacity: "0", transform: "translateX(-200px)" },
          "100%": { opacity: "1", transform: "translateX(0px)" },
        },
        loadFadeFromLeft: {
          "0%": { opacity: "0", transform: "translateX(200px)" },
          "100%": { opacity: "1", transform: "translateX(0px)" },
        },
        slideDown: {
          "0%": { opacity: "0", transform: "translateY(-10px)" },
          "100%": { opacity: "1", transform: "translateY(0px)" },
        },
        fadeInFromRight: {
          "0%": { opacity: "0", transform: "translateX(100%)" },
          "100%": { opacity: "1", transform: "translateX(0)" },
        },
        typing: {
          from: { width: 0 },
          to: { width: "100%" },
        },
        floating: {
          "0%": { transform: "translateY(-3px)" },
          "50%": { transform: "translateY(3px)" },
          "100%": { transform: "translateY(-3px)" },
        },
        showPulse: {
          "50%": { opacity: 0.1 },
        },
        enterFromRight: {
          from: { opacity: "0", transform: "translateX(200px)" },
          to: { opacity: "1", transform: "translateX(0)" },
        },
        enterFromLeft: {
          from: { opacity: "0", transform: "translateX(-200px)" },
          to: { opacity: "1", transform: "translateX(0)" },
        },
        exitToRight: {
          from: { opacity: "1", transform: "translateX(0)" },
          to: { opacity: "0", transform: "translateX(200px)" },
        },
        exitToLeft: {
          from: { opacity: "1", transform: "translateX(0)" },
          to: { opacity: "0", transform: "translateX(-200px)" },
        },
        scaleIn: {
          from: { opacity: "0", transform: "rotateX(-10deg) scale(0.9)" },
          to: { opacity: "1", transform: "rotateX(0deg) scale(1)" },
        },
        scaleOut: {
          from: { opacity: "1", transform: "rotateX(0deg) scale(1)" },
          to: { opacity: "0", transform: "rotateX(-10deg) scale(0.95)" },
        },
        fadeIn: {
          from: { opacity: "0" },
          to: { opacity: "1" },
        },
        fadeOut: {
          from: { opacity: "1" },
          to: { opacity: "0" },
        },

        progress: {
          "0%": {
            transform: "translateX(0) scaleX(0)",
          },
        },
        // scanLeft: {
        //   "0%, 100%": { width: "80%" },
        //   "50%": { width: "120%" },
        // },
        // scanRight: {
        //   "0%, 100%": { width: "120%" },
        //   "50%": { width: "80%" },
        // },
      },
      animation: {
        fadeInFromLeft: "fadeInFromLeft 0.3s ease",
        mildFade: "mildFade 200ms ease", // fadeIn
        loadMildFadeUp: "loadMildFadeUp 0.3s ease",
        loadFadeUp: "loadFadeUp 0.6s ease",
        loadFadeFromRight: "loadFadeFromRight 200ms ease", //enterFromLeft
        loadFadeFromLeft: "loadFadeFromLeft 200ms ease", // enterFromRight
        slideDown: "slideDown 0.07s ease",
        fadeInFromRight: "fadeInFromRight 0.5s ease",
        animate_typing: "typing 1.5s infinite",
        floating: "floating 3s ease infinite",
        showPulse: "showPulse 5s cubic-bezier(0.4, 0, 0.6, 1) infinite",
        exitToLeft: "exitToLeft 250ms ease",
        exitToRight: "exitToRight 250ms ease",
        progress: "progress 1s infinite linear",
        enterFromLeft: "enterFromLeft 250ms ease",
        enterFromRight: "enterFromRight 250ms ease",
        exitToLeft: "exitToLeft 250ms ease",
        exitToRight: "exitToRight 250ms ease",
        scaleIn: "scaleIn 200ms ease",
        scaleOut: "scaleOut 200ms ease",
        fadeIn: "fadeIn 200ms ease",
        fadeOut: "fadeOut 200ms ease",
      },
    },
  },
  plugins: [
    require("@tailwindcss/forms"),
    require("@tailwindcss/typography"),
    require("@tailwindcss/aspect-ratio"),
  ],
}
